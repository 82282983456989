<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('concernManagement.confirm_appointment') }} {{$t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
            <b-overlay :show="loading">
              <b-row>
                <b-col xs="12" sm="12" md="4">
                  <ValidationProvider name="Start date" vid="from_date" v-slot="{ errors }">
                    <b-form-group
                      label-for="from_date">
                      <template v-slot:label>
                        {{ $t('globalTrans.start_date') }}
                      </template>
                      <date-picker
                        id="from_date"
                        v-model="search.from_date"
                        class="form-control"
                        :placeholder="$t('globalTrans.select_date')"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </date-picker>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4">
                  <ValidationProvider name="To date" vid="to_date" v-slot="{ errors }">
                    <b-form-group
                      label-for="from_date">
                      <template v-slot:label>
                        {{ $t('globalTrans.to_date') }}
                      </template>
                      <date-picker
                        id="to_date"
                        v-model="search.to_date"
                        class="form-control"
                        :placeholder="$t('globalTrans.select_date')"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </date-picker>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="3">
                  <b-button size="sm" type="submit" variant="primary" class="mt-20">
                    <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-overlay>
          </b-form>
        </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('concernManagement.confirm_appointment') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col sm="12">
              <div class="quick-filter-wrapper">
                <div class="quick-filter-left">
                  <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                    <b-dropdown-form>
                      <div
                        class="form-group"
                        v-for="(field, index) in labelData"
                        :key="index"
                        >
                        <b-form-checkbox
                          :id="'checkbox-' + field.label_en"
                          v-model="field.show"
                          :name="'checkbox-' + field.label_en"
                          value=1
                          unchecked-value=0
                        >
                          {{ ($i18n.locale==='bn') ? field.label_bn : field.label_en }}
                        </b-form-checkbox>
                      </div>
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
                <div class="quick-filter-right">
                  <b-form-group
                    :label="$t('menu.perpage')"
                    label-for="per-page-select"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="search.limit"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                   <template v-slot:cell(address_detail)="data">
                    {{ getAddress(data.item.user_id) }}
                  </template>
                  <template v-slot:cell(subject)="data">
                    {{ getSubject(data.item.officer.subject_id) }}
                  </template>
                  <template v-slot:cell(mobile)="data">
                    {{ data.item.mobile | mobileNumber }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span v-if="data.item.status === 2"><b-badge pill variant="success">{{ $t('concernManagement.approve')}}</b-badge></span>
                    <span v-if="data.item.status === 4"><b-badge pill variant="danger">{{ $t('externalTradeTraiff.cancel')}}</b-badge></span>
                    <span v-if="data.item.status === 3"><b-badge pill variant="info">{{ $t('concernManagement.complete')}}</b-badge></span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button v-if="data.item.status === 2" variant="iq-bg-success mr-1" size="sm" @click="scheduleComplete(data.item)" class="action-btn edit" :title="$t('concernManagement.complete')"><i class="ri-shield-check-line"></i></b-button>
                    <b-button v-b-modal.modal-details variant="iq-bg-success mr-1" size="sm" @click="details(data.item)" class="action-btn edit" :title="$t('concernManagement.profile_view')"><i class="ri-eye-fill"></i></b-button>
                    <b-button v-if="data.item.status === 2" v-b-modal.modal-6 variant="iq-bg-danger mr-1" size="sm" @click="scheduleCancel(data.item)" class="action-btn edit" :title="$t('globalTrans.cancel')"><i class="ri-delete-back-2-line"></i></b-button>
                    <b-button v-if="data.item.status === 3 || data.item.status === 4" variant="iq-bg-success mr-1" size="sm" @click="endAppointment(data.item)" class="action-btn edit" :title="$t('concernManagement.end_appointment')"><i class="ri-inbox-archive-line"></i></b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <!-- <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal> -->
    <b-modal id="modal-details" size="lg" :title="viewTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
     <Details :items="items" :key="viewitemId"/>
    </b-modal>
    <b-modal id="modal-6" size="md" :title="cancelTitile" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Note :id="rejectItemId" :key="rejectItemId"/>
    </b-modal>
  </div>
</template>
<script>
// import Form from './Form.vue'
import Details from './Details.vue'
import Note from './Note.vue'
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { makeCreateApproveList, makeCreateComplete, endAppointmentApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import i18n from '@/i18n'
import Address from '@/utils/area-type-address'

const defaultColumn = [
  { label_en: i18n.messages.en.globalTrans.sl_no, label_bn: i18n.messages.bn.globalTrans.sl_no, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
  { label_en: i18n.messages.en.concernManagement.user_name, label_bn: i18n.messages.bn.concernManagement.user_name, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
  { label_en: i18n.messages.en.concernManagement.mobile_number, label_bn: i18n.messages.bn.concernManagement.mobile_number, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '12%' } },
  { label_en: i18n.messages.en.concernManagement.address, label_bn: i18n.messages.bn.concernManagement.address, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '12%' } },
  { label_en: i18n.messages.en.concernManagement.subject, label_bn: i18n.messages.bn.concernManagement.subject, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '12%' } },
  { label_en: i18n.messages.en.globalTrans.status, label_bn: i18n.messages.bn.globalTrans.status, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
  { label_en: i18n.messages.en.globalTrans.action, label_bn: i18n.messages.bn.globalTrans.action, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '15%' } }
]

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details, Note
  },
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      search: {
        org_id: 4,
        office_type_id: 0,
        office_id: 0,
        designation_id: 0,
        employee_id: 0,
        limit: 20
      },
      items: [],
      officeTypeList: [],
      officeList: [],
      designationList: [],
      userList: [],
      userLoading: false,
      viewitemId: 0,
      actions: {
        forward: false,
        edit: false,
        details: true,
        toogle: false,
        delete: false
      },
      consultantData: [],
      rejectItemId: '',
      Address: Address,
      valid: ''
    }
  },
  created () {
    this.loadData()
    this.officeTypeList = this.getOfficeTypeList(4)
    this.labelData = this.labelList
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    'search.office_type_id': function (newVal, oldVal) {
      if (newVal) {
        this.officeList = this.getOfficeList(newVal)
      } else {
        this.officeList = []
      }
    },
    'search.office_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.designationList = this.getDesignationList(newVal)
      } else {
        this.designationList = []
      }
    },
    currentLocale: function () {
      if (this.search.org_id) {
        this.officeTypeList = this.getOfficeTypeList(this.search.org_id)
      }
      if (this.search.office_type_id) {
        this.officeList = this.getOfficeList(this.search.office_type_id)
      }
      if (this.search.office_id) {
        this.designationList = this.getDesignationList(this.search.office_id)
      }
    }
  },
  computed: {
    orgList () {
      const list = this.$store.state.CommonService.commonObj.orgProfileList.filter(item => item.value === 4)
        return list.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
        })
    },
    currentLocale () {
      return this.$i18n.locale
    },
    labelList: function () {
      const dataList = defaultColumn
      return dataList.map(item => {
        if (this.$i18n.locale === 'bn') {
          const data = { value: item.label_en, text: item.label_bn }
          return Object.assign({}, item, data)
        } else {
          const data = { value: item.label_en, text: item.label_en }
          return Object.assign({}, item, data)
        }
      })
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        const labelData = {}
        labelData.label = this.$i18n.locale === 'bn' ? item.label_bn : item.label_en
        return Object.assign(item, labelData)
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'name_bn' },
          { key: 'mobile' },
          { key: 'address_detail' },
          { key: 'subject' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'name_en' },
          { key: 'mobile' },
          { key: 'address_detail' },
          { key: 'subject' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    formTitle () {
      return this.$t('concernManagement.make_schedule') + ' ' + this.$t('globalTrans.entry')
    },
    viewTitle () {
      return this.$t('concernManagement.make_schedule') + ' ' + this.$t('globalTrans.details')
    },
    cancelTitile () {
      return this.$t('globalTrans.cancel')
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    }
  },
  methods: {
    getAddress (userId) {
      const Obj = this.userList.find(el => el.user_id === parseInt(userId))
      const address = Address.getPermanentAddress(Obj)
      return address
    },
    getSubject (id) {
      const data = this.$store.state.TradeTariffService.commonObj.subjectList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
      } else {
        return ''
      }
    },
    scheduleComplete (item) {
      window.vm.$swal({
        title: window.vm.$t('concernManagement.complete_status'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeStatus(item)
        }
      })
    },
    async changeStatus (item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const loadingState = { loading: false, listReload: false }
      const result = await RestApi.putData(tradeTariffServiceBaseUrl, `${makeCreateComplete}/${item.id}`)
      if (result.success) {
        window.vm.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        } else {
          window.vm.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
        this.$store.dispatch('mutateCommonProperties', { listReload: true })
      }
    },
    endAppointment (item) {
      window.vm.$swal({
        title: window.vm.$t('concernManagement.end_appointment_status'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.finshAppointmentStatus(item)
        }
      })
    },
    async finshAppointmentStatus (item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const loadingState = { loading: false, listReload: false }
      const result = await RestApi.putData(tradeTariffServiceBaseUrl, `${endAppointmentApi}/${item.id}`)
      if (result.success) {
        window.vm.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        } else {
          window.vm.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
        this.$store.dispatch('mutateCommonProperties', { listReload: true })
      }
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.CommonService.commonObj.officeTypeList.filter(item => item.status === 1)
      if (orgId) {
        return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      }
      return officeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1 && item.office_type_id === officeTypeId)
      return officeList
    },
    getDesignationList (officeId) {
      const list = this.$store.state.CommonService.commonObj.assignDesignationList.filter(item => item.office_id === officeId && item.office_type_id === this.search.office_type_id && item.org_id === this.search.org_id)
      return list.map(obj => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    createSchedule (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.items = item
    },
    scheduleCancel (item) {
      this.rejectItemId = item.id
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(tradeTariffServiceBaseUrl, makeCreateApproveList, params)
      if (result.success) {
        this.userList = result.userList
        const listData = result.data.data.map((item, index) => {
          const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(org => org.value === parseInt(item.officer.org_id))
          const officeTypebj = this.$store.state.CommonService.commonObj.officeTypeList.find(type => type.value === parseInt(item.officer.office_type_id))
          const officeObj = this.$store.state.CommonService.commonObj.officeList.find(type => type.value === parseInt(item.officer.office_id))
          const desingationObj = this.$store.state.CommonService.commonObj.designationList.find(desingation => desingation.value === parseInt(item.officer.designation_id))
          const userObj = this.userList.find(user => user.user_id === parseInt(item.user_id))
          const orgData = {}
          const officeTypeData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org_name = orgObj.text_en
            orgData.org_name_bn = orgObj.text_bn
          } else {
            orgData.org_name = ''
            orgData.org_name_bn = ''
          }
          if (typeof officeTypebj !== 'undefined') {
            officeTypeData.office_type_name = officeTypebj.text_en
            officeTypeData.office_type_name_bn = officeTypebj.text_bn
          } else {
            officeTypeData.office_type_name = ''
            officeTypeData.office_type_name_bn = ''
          }
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office_name = officeObj.text_en
            officeData.office_name_bn = officeObj.text_bn
          } else {
            officeData.office_name = ''
            officeData.office_name_bn = ''
          }
          const designationData = {}
          if (typeof desingationObj !== 'undefined') {
            designationData.desingation_name = desingationObj.text_en
            designationData.desingation_name_bn = desingationObj.text_bn
          } else {
            designationData.desination_name = ''
            designationData.desingation_name_bn = ''
          }
          const userData = {}
          if (userObj !== undefined) {
            userData.name_en = userObj.name
            userData.name_bn = userObj.name_bn
            userData.mobile = userObj.mobile
          } else {
            userData.name_en = ''
            userData.name_bn = ''
            userData.mobile = ''
          }
          return Object.assign({}, item, { serial: index }, orgData, officeTypeData, officeData, designationData, userData)
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
