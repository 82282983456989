<template>
  <b-container fluid>
      <b-overlay :show="loading">
        <b-row style="line-height: 20px">
          <template>
            <div style="font-size:18px; background-color: #17a2b8;border-radius:15px" class="p-1">
              <h5 class="text-white text-center">
                {{ $t('concernManagement.concern_off_pro') }}
              </h5>
            </div>
          </template>
        </b-row>
        <b-row class="mt-3">
            <b-col lg="6" md="6" sm="6" xs="12">
              <div class="text-justify" style="margin-left:50px">
                <h6 style="font-weight:bold">{{ currentLocale === 'en' ? users.text_en : users.text_bn}}</h6>
                <h6>{{ currentLocale === 'en' ? detailData.desingation_name : detailData.desingation_name_bn }}</h6>
                <h6>{{ currentLocale === 'en' ? detailData.office_type_name : detailData.office_type_name_bn }}</h6>
                <h6>{{ currentLocale === 'en' ? detailData.org_name : detailData.org_name_bn }}</h6>
                <h6>{{ currentLocale === 'en' ? detailData.officer.address : detailData.officer.address_bn }}</h6>
              </div>
            </b-col>
            <b-col lg="6" md="6" sm="6" xs="12">
                <template>
                    <div class="text-center">
                        <b-img :src="tradeTariffServiceBaseUrl + '/storage/' + detailData.officer.photo" style="width:120px; height:120px" rounded="circle" alt="Circle image"></b-img>
                    </div>
                </template>
            </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col lg="12" md="12" sm="12" xs="12">
              <div class="text-justify" style="margin-left:50px">
                <h6>{{ currentLocale === 'en' ? detailData.officer.expertise_detail : detailData.officer.expertise_detail_bn }}</h6>
              </div>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col lg="6" md="6" sm="6" xs="12">
            <div class="text-justify" style="margin-left:50px">
              <h4>{{ $t('concernManagement.contact_info')}}</h4>
              <h6>{{ $t('concernManagement.telephone')}}: {{ $n(detailData.officer.telephone, { useGrouping: false }) }}</h6>
              <h6>{{ $t('concernManagement.mobile')}}: {{ this.$i18n.locale === 'en' ? '0' : '০' }}{{ $n(detailData.officer.mobile, { useGrouping: false }) }}</h6>
              <h6>{{ $t('globalTrans.email')}}: {{ detailData.officer.email }}</h6>
              <h6 v-if="detailData.officer.website">{{ $t('concernManagement.website')}}: {{ detailData.officer.website }}</h6>
            </div>
          </b-col>
          <template v-if="detailData.status === 2 || detailData.status === 3">
            <b-col lg="6" md="6" sm="6" xs="12" v-if="detailData.details.length">
              <div class="text-justify" style="margin-left:72px">
                <h4>{{ $t('concernManagement.consultation_time')}}</h4>
              </div>
              <template>
                <div class="table-wrapper table-responsive">
                    <table class="table table-striped table-hover table-bordered">
                      <b-thead>
                        <tr>
                          <b-th class="text-left" style="font-size:12px">{{ $t('globalTrans.sl_no') }}</b-th>
                          <b-th class="text-left" style="font-size:12px">{{ $t('globalTrans.date') }}</b-th>
                          <b-th class="text-left" style="font-size:12px">{{ $t('concernManagement.start_time') }}</b-th>
                          <b-th class="text-left" style="font-size:12px">{{ $t('concernManagement.end_time') }}</b-th>
                        </tr>
                      </b-thead>
                      <b-tbody>
                        <template v-if="detailData.details && detailData.details.length">
                          <b-tr v-for="(item, index) in detailData.details" :key="index">
                            <b-td class="text-center" style="font-size:14px">{{ $n(index+1, { useGrouping: false }) }}</b-td>
                            <b-td class="text-center" style="font-size:14px">{{ item.date | dateFormat }}</b-td>
                            <b-td class="text-center" style="font-size:14px">{{ timeFormat(item.start_time)}}</b-td>
                            <b-td class="text-center" style="font-size:14px">{{ timeFormat(item.end_time) }}</b-td>
                          </b-tr>
                        </template>
                      </b-tbody>
                    </table>
                  </div>
              </template>
            </b-col>
          </template>
          <template v-if="detailData.status === 4">
            <b-col lg="6" md="6" sm="6" xs="12">
              <div class="text-justify" style="margin-left:72px">
                <h4>{{ $t('externalTradeTraiff.cancellation_status')}}</h4>
              </div>
              <template>
                <div class="table-wrapper table-responsive">
                    <table class="table table-striped table-hover table-bordered">
                      <b-thead>
                        <tr>
                          <b-th class="text-left" style="font-size:12px">{{ detailData.note }}</b-th>
                        </tr>
                      </b-thead>
                    </table>
                  </div>
              </template>
            </b-col>
          </template>
        </b-row>
        <b-row class="text-right">
          <b-col>
            <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-details')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-overlay>
  </b-container>
</template>
<script>
import RestApi, { tradeTariffServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
export default {
    props: ['items'],
    components: {
    },
    data () {
      return {
        tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
        detailData: [],
        users: [],
        loading: false
      }
    },
     created () {
        this.detailData = this.items
        this.getUserList(this.detailData.officer.employee_id)
     },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
      timeFormat (time) {
        const timeConvert = time
        const timeIndexHour = timeConvert.split(':')[0]
        const timeIndexMiniute = timeConvert.split(':')[1]
        var hours = timeIndexHour
        var minutes = timeIndexMiniute
        var ampm = this.$i18n.locale === 'en' ? (hours >= 12 ? 'pm' : 'am') : (hours >= 12 ? 'পিএম' : 'এএম')
        hours = hours % 12
        hours = hours ?? 12 // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes
        var strTime = this.$n(hours) + ':' + this.$n(minutes) + ' ' + ampm
        return strTime
      },
      async getUserList (employeeId) {
        this.loading = true
        const params = {}
        const result = await RestApi.getData(authServiceBaseUrl, '/user-management/user/get-all', params)
        if (result.success) {
          this.users = result.data.find(obj => obj.value === employeeId)
        }
        this.loading = false
      }
    }
}

</script>
